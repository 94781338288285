.contact{
    .contact-header{
        h1{
            margin-bottom: 1rem;
        }
        text-align: center;
        width: 90%;
        margin: auto;
    }

    .contact-content{
        margin: 4rem auto;
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;

        @media screen and (max-width: $lg) {
            flex-direction: column;  
            width: 90%;  
            margin: 2rem auto;
        }

        >div{width: 100%;}

        .concio-info{
            padding: 0 1rem;
            @media screen and (max-width: $lg) {
                margin-bottom: 2rem;
            }

            .img-container{
                width: 250px;
                margin: auto;
                @media screen and (max-width: $lg) {
                    width: 200px;
                }
                
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }

            ul{
                list-style: none;
                padding-left: 0;
                li{
                    font-family: "Lato Bold";
                    font-size: 0.875rem;
                    margin-bottom: 0;
                    line-height: 17px;
                }
            }
        }

        .team{
            padding: 0 1rem;
            border-left: 2px solid $secondary;
            @media screen and (max-width: $lg) {
                border-left: none;
                border-top: 2px solid $secondary;
                padding-top: 2rem;
            }
            .team-row{
                margin-bottom: 2rem;
                .category {
                    font-family: "Lato Bold";
                }
                .email{
                    color: $primary;
                    @media screen and (max-width: $lg) {
                        font-size: 0.75rem;
                    }
                }
            }
    
        }
    }
}