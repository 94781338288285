.exhibitors{
    
    h1{
        span{
            @media screen and (max-width: $md) {
                display: none;;
            }
        }
    }
    .banner{
        .btn-container{
            a + a{
                margin-left: 2rem;
                @media screen and (max-width: $md) {
                    margin-left: 0;
                }
            }
        }
        .img-container{
            background-image: url("./../../img/exposants.jpeg");
        }
    }

    .exhibitors-container{
        display: flex;
        justify-content: center;
        margin: 3rem auto;

        @media screen and (max-width: $lg) {
            display: block;
        }
        
        .exhibitors-block{
            margin-bottom: 2rem;
            h2{
                font-size: 1.5rem;
                color: $primary;
                margin-bottom: 0.5rem;

                @media screen and (max-width: $lg) {
                    text-align: left;
                    font-size: 1rem;
                }
            }

            .exhibitors-list{
                @media screen and (max-width: $lg) {
                    border-left: 2px solid $primary;
                    padding-left: 1rem;
                }
                p, a{
                    font-size: 1rem;
                    line-height: 24px;
                    color: $text;
                }
    
                a{
                    display: block;
    
                    &:hover{
                        color: $secondary;
                    }
                }
            }
            

        }
        .exhibitors-left{
            width: 100%;
            text-align: right;
            border-right: 3px solid $secondary;
            padding-right: 3rem;

            @media screen and (max-width: $lg) {
                text-align: left;
                border-right: none;
                padding-right: 0;
            }
        }
        .exhibitors-right{
            width: 100%;
            padding-left: 3rem;
            text-align: left;
            @media screen and (max-width: $lg) {
                padding-left: 0
            }
        }

    }
}