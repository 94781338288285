.exhibit{

    h1{
        @media screen and (max-width: $md) {
            span{
                display: none;
            }
        }
    }
    
    .banner{
        .content-container{
            p{
                margin-bottom: 1rem;
            }
        }

        .img-container{
            background-image: url("./../../img/exposer.jpeg");
        }
    }

    .exhibit-intro{
        margin: 3rem auto;
        
        @media screen and (max-width: $md) {
            margin: 1.5rem auto 3rem;
        }

        p{
            margin-bottom: 1rem;
        }
    }

    .exhibit-process{
        .title{
            color: $primary;
            font-size: 1.4rem;
            font-family: "Lato Bold";
            margin-bottom: 3rem;

            @media screen and (max-width: $md) {
                margin-bottom: 1rem;
                text-align: center;
            }
        }

        .steps, .steps-mb{
            margin-bottom: 4rem;
            .step{
                &:first-child .step-header{
                    background-image: url("./../../img/step1.jpg");
                }
                &:nth-child(2) .step-header{
                    background-image: url("./../../img/step2.jpg");
                }
                &:nth-child(3) .step-header{
                    background-image: url("./../../img/step3.jpg");
                    background-position: center;
                }
                .step-header{
                    background-size: cover;
                    background-position: center;
                    height: 6.25rem;
                    position: relative;
                    overflow: hidden;
                    .number{
                        position: absolute;
                        left: 0;
                        background-color: $white;
                        border: 3px solid $primary;
                        border-radius: 50%;
                        height: 6.25rem;
                        width: 6.25rem;
                        transform: translateX(-50%);
                        display: flex;
                        align-items: center;
                        justify-content: right;
                        font-family: "Lato Bold";

                        span{
                            font-size: 3.75rem;
                            transform: translateX(-1rem);
                            color: $primary
                        }
                    }
                }
                .step-content{
                    border-left: 4px dashed $primary;

                    .content-container{
                        width: 90%;
                        max-width: 56rem;
                        margin: auto;
                        padding: 2rem 0 4rem;
                        
                        p{
                            margin-bottom: 1rem;
                        }

                        .btn-container{
                            text-align: center;
                        }
                    }
                }

                &:last-child{
                    .content-container{
                        padding-bottom: 2rem;
                    }
                }
            }
        }

        .steps {
            @media screen and (max-width: $md) {
                display: none;
            }
        }
        .steps-mb{
            margin-bottom: 4rem;
            @media screen and (min-width: $md) {
                display: none;
            }

            .swiper-exhibit{
                padding: 0.6rem;
                margin-bottom: 1rem;

                .step{
                    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 4px rgba(0, 0, 0, 0.25);
                    height: auto;
                    .step-content{
                        border-left: none;

                        .btn-container{
                            margin-bottom: 4rem;
                        }
                    }
                }
           
                .swiper-button-next, .swiper-button-prev{
                    top: 90%;
                    display: flex;
                    flex-direction: column;
                    width: 100px;
                    
                    &::after{
                        content: "";
                    }

                    img{
                        height: 30px;
                        width: 30px;
                    }

                    p{
                        font-size: 0.8rem;
                        color: $primary;
                    }
                }
                .swiper-button-next{
                    right: 6%;
                }
                .swiper-button-prev{
                    left: 6%;
                }
            }

            
        }
    }
}