.workshops{
    h1{
        @media screen and (max-width: $xl){
            span{
                display: none;
            }
        }
    }

    .banner{
        .container {
            .sup-title{
                font-family: "Lato Bold";
                margin-bottom: 1rem;
                @media screen and (max-width: $xl){
                    display: none;
                }
            }

            .img-container{
                background-image: url("./../../img/tables-rondes.jpeg");
            }
        }
    }

    .workshops-container{
        margin: 3rem auto;
        
        @media screen and (max-width: $xl){
            display: none; 
        }
        .workshop{
            display: flex;
            margin-bottom: 3rem;
            .heading{ 
                text-align: right;
                min-width: 350px;
                max-width: 350px;
                border-right: 2px solid $primary ;
                padding-right: 1rem;
                h2{
                    font-family: "Fahkwang SemiBold";
                    color: $primary;
                    font-size: 2.375rem; 
                    margin-bottom: 0.5rem
                }

                .schedule{  
                    font-family: "Fahkwang SemiBold";
                    color: $secondary;
                    font-size: 1.25rem; 
                }
            }
            .content{
                padding-left: 1rem;
                .subject{
                    margin-bottom: 1rem;
                    font-family: "Lato Bold";
                }

                strong{
                    font-family: "Lato Bold";
                }

                em{
                    font-family: "Lato Italic";
                }
            }
        }
    }

    .workshops-container-mb{
        @media screen and (min-width: $xl){
            display: none; 
        }
        .swiper-workshops{
            margin: 3rem auto;
            padding: 0.8rem;
            padding-bottom: 3rem;

            .swiper-slide{
                box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 4px rgba(0, 0, 0, 0.25);
                height: auto;
                .heading{ 
                    .top{
                       padding: 1rem;
                        h2{
                            font-family: "Fahkwang SemiBold";font-size: 2.125rem; 
                            color: $primary;
                            @media screen and (max-width: $md){
                                text-align: left;
                            }

                        }
                        
                        .schedule{ 
                            color: $primary;
                            text-align: right;
                            font-family: "Fahkwang SemiBold";
                            font-size: 1.25rem; 
                        }
                   }

                   .subject{
                        padding: 1rem;
                        background-color: $secondary;
                        text-align: center;
                        p{
                            color: $white;
                            @media screen and (max-width: $md){
                                font-size: 0.875rem !important;
                            }
                        }
                   }
                }

                .content{
                    margin: 1.5rem 1rem;

                    strong{
                        font-family: "Lato Bold";
                    }
    
                    em{
                        font-family: "Lato Italic";
                    }
                }
            }

        }
    }

}