.reservation-summary{
    .summary-header{
        text-align: center;

        h1{
            margin-bottom: 1rem;
        }
    }

    .summary-content{

        h2{
            margin-bottom: 1rem;
        }
        
        width: fit-content;
        margin: 2rem auto 0;
        .row{
            display: flex;
            margin-bottom: 1rem;
            .label {
                font-family: "Lato Bold";
                margin-right: 1rem;
            }

            p:nth-child(2){
                border-bottom: 1px dashed rgb(179, 179, 179);
            }
        }

        .row-2-col{
           div{
                width: 100%;
           }
           div + div {
            margin-left: 1rem;
           }
        }
    }

    .recap{
        background-color: $primary;
        color: $white;
        padding: 1rem;
        margin: 2rem auto;
        .recap--inner{
            display: flex;
            margin: auto;
            h2{
                margin-bottom: 1rem;
                margin-top: 0;
                text-align: center;
            }

            >div{
                width: 100%;
            }

            .price-details{
                width: max-content;
                margin: 2rem auto;
                .label{
                    display: inline-block;
                    width: 7rem;
                    text-align: right;
                }
                p{
                    color: $white;
                    font-size: 1.1rem;
    
                    &:not(:last-child){
                        margin-top: 1rem;
                    }

                    span[class*="price"]{
                        margin-left: 2rem;
                        letter-spacing: 0.6px;
                        border-bottom: 1px dashed $white;
                        width: 7rem;
                        display: inline-block;
                    }

                }
            }
        }
    }
}