/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato Italic";
  src: url("../../fonts/Lato-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato Bold";
  src: url("../../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato Light";
  src: url("../../fonts/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Fahkwang SemiBold";
  src: url("../../fonts/Fahkwang-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
.header {
  background-color: #fff;
  padding: 1rem 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 31rem) {
  .header {
    padding: 0.5rem 0;
  }
}
.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .container .logo-container {
  display: flex;
  width: 250px;
  align-items: center;
}
.header .container .logo-container .logo {
  width: 85px;
}
@media screen and (max-width: 31rem) {
  .header .container .logo-container .logo {
    width: 60px;
  }
}
.header .container .logo-container .logo img {
  width: 100%;
  object-fit: contain;
}
.header .container .logo-container .logo-partner {
  text-align: center;
  width: 165px;
  margin-left: 1rem;
}
@media screen and (max-width: 31rem) {
  .header .container .logo-container .logo-partner {
    width: 130px;
  }
}
.header .container .logo-container .logo-partner p {
  font-family: "Lato Bold";
  color: #005995;
  margin-bottom: 0.2rem;
  font-size: 1rem;
}
@media screen and (max-width: 31rem) {
  .header .container .logo-container .logo-partner p {
    font-size: 0.8rem;
  }
}
.header .container .logo-container .logo-partner img {
  width: 93px;
}
.header .container nav ul {
  display: flex;
  list-style: none;
}
.header .container nav ul li + li {
  margin-left: 2rem;
}
.header .container nav ul a {
  color: #005995;
  font-family: "Lato Bold";
  font-size: 1.25rem;
}
.header .container .burger-icon {
  display: none;
  cursor: pointer;
  transform: scale(1.2);
}
@media screen and (max-width: 66rem) {
  .header .container nav {
    display: none;
  }
  .header .container .burger-icon {
    display: inline-block;
  }
}

.menu-mb {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
}
@media screen and (max-width: 66rem) {
  .menu-mb {
    display: block;
  }
  .menu-mb nav {
    width: 90%;
    margin: 2rem auto 0;
    height: calc(100% - 350px);
    max-height: 27rem;
  }
  .menu-mb nav ul {
    list-style: none;
    text-align: right;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .menu-mb nav ul a {
    color: #005995;
    font-family: "Lato Bold";
    font-size: 1.25rem;
  }
  .menu-mb .socials {
    margin: auto;
    width: fit-content;
    height: 200px;
    display: flex;
    align-items: center;
  }
  .menu-mb .socials a {
    background-color: #005995;
    border-radius: 50%;
    display: inline-block;
    padding: 0.5rem;
  }
  .menu-mb .socials a img {
    height: 100%;
    object-fit: contain;
  }
  .menu-mb .socials a.fb {
    height: 60px;
    width: 60px;
    margin-right: 3rem;
  }
  .menu-mb .socials a.linkedin {
    height: 65px;
    width: 65px;
  }
}

.footer {
  margin-top: 3rem;
  background-color: #005995;
}
@media screen and (max-width: 56rem) {
  .footer {
    display: none;
  }
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer .container ul {
  display: flex;
  list-style: none;
  padding-left: 0;
}
.footer .container ul li {
  color: #fff;
  margin-bottom: 0;
  font-size: 0.75rem;
}
.footer .container ul li:first-child {
  padding-right: 1rem;
}
.footer .container ul li:not(:first-child) {
  border-left: 1px solid #fff;
  padding: 0 1rem;
}
.footer .container ul li a {
  color: #fff;
  font-size: 0.75rem;
}
.footer .container .socials {
  display: flex;
  align-items: center;
}
.footer .container .socials a img {
  height: 100%;
  object-fit: contain;
}
.footer .container .socials a.fb {
  height: 30px;
  margin-right: 3rem;
}
.footer .container .socials a.linkedin {
  height: 37px;
}

.partners h2 {
  font-family: "Lato";
  font-size: 1.875rem;
  color: #E85c5b;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 31rem) {
  .partners h2 {
    font-size: 1.25rem;
  }
}

.swiper-partners {
  margin-top: 2rem;
  width: 100%;
  height: 100px;
  text-align: center;
}
@media screen and (max-width: 56rem) {
  .swiper-partners {
    margin-bottom: 3rem;
    margin-top: 0;
  }
}
.swiper-partners a {
  display: inline-block;
  height: 100px;
  max-width: 160px;
}
@media screen and (max-width: 31rem) {
  .swiper-partners a {
    height: 80px;
  }
}
.swiper-partners a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper-partners .swiper-button-next, .swiper-partners .swiper-button-prev {
  color: #005995;
}
.swiper-partners .swiper-button-next::after, .swiper-partners .swiper-button-prev::after {
  font-size: 2rem;
}

body {
  font-family: "Lato";
}

.container {
  max-width: 71.25rem;
  margin: auto;
  width: 90%;
}

h1 {
  font-family: "Lato";
  font-size: 1.875rem;
  line-height: 1.2;
  color: #E85c5b;
  text-transform: uppercase;
  text-align: center;
  margin: 3rem auto;
}
@media screen and (max-width: 56rem) {
  h1 {
    margin: 2rem auto;
    font-size: 1.25rem;
  }
}

h2 {
  font-family: "Lato Bold";
  font-size: 1.5rem;
  line-height: 1.2;
}
@media screen and (max-width: 31rem) {
  h2 {
    font-size: 1.25rem;
    text-align: center;
  }
}

p,
li {
  line-height: 24px;
  font-family: "Lato";
  font-size: 1rem;
  color: #424242;
}
@media screen and (max-width: 31rem) {
  p,
li {
    font-size: 0.75rem;
    line-height: 20px;
  }
}

.p-bold {
  font-family: "Lato Bold";
}

.p-italic {
  font-family: "Lato Italic";
}

ul {
  list-style: disc;
  padding-left: 2rem;
}
ul li {
  margin-bottom: 0.5rem;
}

input,
select {
  border-radius: 4px;
  padding: 0.5rem;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(218, 218, 218);
}
input:focus,
select:focus {
  outline: 2px solid #005995;
}

.alert {
  text-align: center;
  display: block;
  width: 90%;
  margin: 0 auto 2rem;
}

.alert-success {
  color: #28a745;
}

.alert-error {
  color: #E85c5b;
}

.btn {
  font-family: "Lato Bold";
  font-size: 0.875rem;
  text-transform: uppercase;
  border: 1px solid #E85c5b;
  width: fit-content;
  background-color: #E85c5b;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  color: #fff;
  display: inline-block;
  transition: 0.4s;
  cursor: pointer;
}
.btn.disabled {
  background-color: #005995;
  border: none;
}
.btn.disabled:hover {
  background-color: #005995;
  cursor: default;
  color: #fff;
}
.btn:hover {
  color: #E85c5b;
  background-color: #fff;
}

.display-none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

@media screen and (max-width: 31rem) {
  .btn-container {
    text-align: center;
  }
}

.banner {
  background-color: #005995;
  color: #fff;
  padding: 6px 0;
}
@media screen and (max-width: 56rem) {
  .banner {
    padding: 1rem 0;
  }
}
.banner p {
  color: #fff;
}
.banner .container {
  display: flex;
  justify-content: space-between;
}
.banner .container .img-container {
  width: 15.6rem;
  min-height: 100%;
  min-width: 250px;
  background-image: url("./../../img/img-site-02.jpg");
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 56rem) {
  .banner .container .img-container {
    display: none;
  }
}
.banner .container .content-container {
  margin: 1rem 0;
  margin-right: 2rem;
}
@media screen and (max-width: 56rem) {
  .banner .container .content-container {
    margin-right: 0;
  }
}
.banner .container .content-container p {
  font-size: 1.125rem;
}
@media screen and (max-width: 56rem) {
  .banner .container .content-container p {
    font-size: 0.875rem;
  }
}

.banner-img-left .container {
  flex-direction: row-reverse;
}
.banner-img-left .container .img-container {
  margin-right: 10%;
}

.banner-img-right .content-container {
  max-width: 46rem;
}

.swiper .swiper-pagination-bullet {
  background-color: #D9D9D9;
  opacity: 1;
}
.swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #808080;
}

.home h1 {
  font-family: "Lato Bold";
  color: #fff;
  font-size: 2.25rem;
  text-transform: capitalize;
  text-align: left;
  margin: 0;
}
@media screen and (max-width: 31rem) {
  .home h1 {
    font-size: 1.25rem;
  }
}
.home .banner .content-container {
  margin: 1.5rem 0;
}
@media screen and (max-width: 31rem) {
  .home .banner .content-container {
    margin: 1rem 0;
  }
}
.home .banner .content-container .sup-title {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 31rem) {
  .home .banner .content-container .sup-title {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}
.home .hero-video {
  margin-top: 2rem;
}
.home .hero-video iframe {
  width: 100%;
  height: 50vh;
}
.home .introduction {
  margin: 3rem auto;
}
.home .introduction .data-circle-container {
  margin: 3rem auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 65rem;
  row-gap: 1rem;
}
@media screen and (max-width: 41rem) {
  .home .introduction .data-circle-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.home .introduction .data-circle-container .data-circle {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home .introduction .data-circle-container .data-circle .circle {
  width: 120px;
  height: 120px;
  border: 7px solid #005995;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E85c5b;
  font-family: "Lato Light";
  font-size: 3.125rem;
}
.home .introduction .data-circle-container .data-circle .description {
  color: #005995;
  max-width: 120px;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
}
.home .cta-2-col {
  display: flex;
  text-align: center;
}
@media screen and (max-width: 41rem) {
  .home .cta-2-col {
    flex-direction: column;
  }
}
.home .cta-2-col .p-bold {
  margin-bottom: 1rem;
}
.home .cta-2-col div:first-child {
  border-right: 2px solid #005995;
  padding-right: 2rem;
}
@media screen and (max-width: 41rem) {
  .home .cta-2-col div:first-child {
    border-right: none;
    padding-right: 0;
    margin: 1rem auto 3rem;
  }
}
.home .cta-2-col div:nth-child(2) {
  padding-left: 2rem;
}
@media screen and (max-width: 41rem) {
  .home .cta-2-col div:nth-child(2) {
    padding-left: 0;
  }
}
@media screen and (max-width: 41rem) {
  .home .cta-2-col div {
    max-width: 28rem;
    margin: auto;
  }
}
.home section.news {
  margin: 5rem auto;
  padding: 2rem 0;
}
@media screen and (max-width: 41rem) {
  .home section.news {
    background-color: #005995;
    width: 100%;
  }
}
.home section.news h2 {
  margin-bottom: 1rem;
}
@media screen and (max-width: 41rem) {
  .home section.news h2 {
    color: #fff;
    text-align: center;
  }
}
@media screen and (max-width: 41rem) {
  .home section.news .news-container {
    display: none;
  }
}
.home section.news .news-image {
  min-width: 30%;
  background-size: cover;
  background-position: center;
}
.home section.news .news-single {
  display: flex;
  margin-bottom: 3px;
  background-color: #005995;
}
.home section.news .news-single .news-content {
  padding: 1rem;
}
.home section.news .news-single .news-content p {
  color: #fff;
}
.home section.news .news-single .news-content a {
  color: #db8989;
}
.home section.news .news-single.bg-white {
  background-color: #fff;
}
.home section.news .news-single.bg-white p {
  color: #424242;
}
.home section.news .news-single.bg-white a {
  color: #E85c5b;
}
.home section.news .news-single strong {
  font-family: "Lato Bold";
}
.home section.news .swiper-news {
  display: none;
}
@media screen and (max-width: 41rem) {
  .home section.news .swiper-news {
    display: block;
  }
}
.home section.news .swiper-news .swiper-wrapper {
  margin-top: 1.5rem;
}
.home section.news .swiper-news .swiper-wrapper .swiper-slide {
  background-color: #fff;
  padding: 3px;
}
.home section.news .swiper-news .swiper-wrapper .swiper-slide .news-image {
  height: 150px;
  background-size: cover;
  background-position: center;
}
.home section.news .swiper-news .swiper-wrapper .swiper-slide .news-content .news-title {
  background-color: #E85c5b;
  color: #fff;
  padding: 0.8rem;
  font-family: "Lato";
}
.home section.news .swiper-news .swiper-wrapper .swiper-slide .news-content .content-bottom {
  padding: 1rem 0.8rem;
}
.home section.news .swiper-news .swiper-wrapper .swiper-slide .news-content .content-bottom .news-date {
  margin-top: 2rem;
  text-align: right;
}
.home section.news .swiper-news .swiper-wrapper .swiper-slide .news-content a {
  color: #E85c5b;
}

@media screen and (max-width: 56rem) {
  .workshops h1 span {
    display: none;
  }
}
.workshops .banner .container .sup-title {
  font-family: "Lato Bold";
  margin-bottom: 1rem;
}
@media screen and (max-width: 56rem) {
  .workshops .banner .container .sup-title {
    display: none;
  }
}
.workshops .banner .container .img-container {
  background-image: url("./../../img/tables-rondes.jpeg");
}
.workshops .workshops-container {
  margin: 3rem auto;
}
@media screen and (max-width: 56rem) {
  .workshops .workshops-container {
    display: none;
  }
}
.workshops .workshops-container .workshop {
  display: flex;
  margin-bottom: 3rem;
}
.workshops .workshops-container .workshop .heading {
  text-align: right;
  min-width: 350px;
  max-width: 350px;
  border-right: 2px solid #005995;
  padding-right: 1rem;
}
.workshops .workshops-container .workshop .heading h2 {
  font-family: "Fahkwang SemiBold";
  color: #005995;
  font-size: 2.375rem;
  margin-bottom: 0.5rem;
}
.workshops .workshops-container .workshop .heading .schedule {
  font-family: "Fahkwang SemiBold";
  color: #E85c5b;
  font-size: 1.25rem;
}
.workshops .workshops-container .workshop .content {
  padding-left: 1rem;
}
.workshops .workshops-container .workshop .content .subject {
  margin-bottom: 1rem;
  font-family: "Lato Bold";
}
.workshops .workshops-container .workshop .content strong {
  font-family: "Lato Bold";
}
.workshops .workshops-container .workshop .content em {
  font-family: "Lato Italic";
}
@media screen and (min-width: 56rem) {
  .workshops .workshops-container-mb {
    display: none;
  }
}
.workshops .workshops-container-mb .swiper-workshops {
  margin: 3rem auto;
  padding: 0.8rem;
  padding-bottom: 3rem;
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 4px rgba(0, 0, 0, 0.25);
  height: auto;
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .top {
  padding: 1rem;
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .top h2 {
  font-family: "Fahkwang SemiBold";
  font-size: 2.125rem;
  color: #005995;
}
@media screen and (max-width: 31rem) {
  .workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .top h2 {
    text-align: left;
  }
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .top .schedule {
  color: #005995;
  text-align: right;
  font-family: "Fahkwang SemiBold";
  font-size: 1.25rem;
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .subject {
  padding: 1rem;
  background-color: #E85c5b;
  text-align: center;
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .subject p {
  color: #fff;
}
@media screen and (max-width: 31rem) {
  .workshops .workshops-container-mb .swiper-workshops .swiper-slide .heading .subject p {
    font-size: 0.875rem !important;
  }
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .content {
  margin: 1.5rem 1rem;
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .content strong {
  font-family: "Lato Bold";
}
.workshops .workshops-container-mb .swiper-workshops .swiper-slide .content em {
  font-family: "Lato Italic";
}

.about .about-content {
  margin: 3rem auto;
}
.about .about-content .img-container {
  display: flex;
  justify-content: space-around;
  margin: 3rem auto;
}
@media screen and (max-width: 41rem) {
  .about .about-content .img-container {
    display: none;
  }
}
.about .about-content .img-container div {
  height: 150px;
}
.about .about-content .img-container div img {
  height: 100%;
  object-fit: contain;
}
.about .about-content .swiper-about {
  display: none;
  margin: 2rem auto;
  max-width: 20rem;
  padding-bottom: 2rem;
}
@media screen and (max-width: 41rem) {
  .about .about-content .swiper-about {
    display: block;
  }
}
.about .about-content .swiper-about .swiper-slide div {
  height: 150px;
  text-align: center;
}
@media screen and (max-width: 31rem) {
  .about .about-content .swiper-about .swiper-slide div {
    height: 120px;
  }
}
.about .about-content .swiper-about .swiper-slide div img {
  height: 100%;
  object-fit: contain;
  max-width: 100%;
}

.contact .contact-header {
  text-align: center;
  width: 90%;
  margin: auto;
}
.contact .contact-header h1 {
  margin-bottom: 1rem;
}
.contact .contact-content {
  margin: 4rem auto;
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 41rem) {
  .contact .contact-content {
    flex-direction: column;
    width: 90%;
    margin: 2rem auto;
  }
}
.contact .contact-content > div {
  width: 100%;
}
.contact .contact-content .concio-info {
  padding: 0 1rem;
}
@media screen and (max-width: 41rem) {
  .contact .contact-content .concio-info {
    margin-bottom: 2rem;
  }
}
.contact .contact-content .concio-info .img-container {
  width: 250px;
  margin: auto;
}
@media screen and (max-width: 41rem) {
  .contact .contact-content .concio-info .img-container {
    width: 200px;
  }
}
.contact .contact-content .concio-info .img-container img {
  width: 100%;
  object-fit: contain;
}
.contact .contact-content .concio-info ul {
  list-style: none;
  padding-left: 0;
}
.contact .contact-content .concio-info ul li {
  font-family: "Lato Bold";
  font-size: 0.875rem;
  margin-bottom: 0;
  line-height: 17px;
}
.contact .contact-content .team {
  padding: 0 1rem;
  border-left: 2px solid #E85c5b;
}
@media screen and (max-width: 41rem) {
  .contact .contact-content .team {
    border-left: none;
    border-top: 2px solid #E85c5b;
    padding-top: 2rem;
  }
}
.contact .contact-content .team .team-row {
  margin-bottom: 2rem;
}
.contact .contact-content .team .team-row .category {
  font-family: "Lato Bold";
}
.contact .contact-content .team .team-row .email {
  color: #005995;
}
@media screen and (max-width: 41rem) {
  .contact .contact-content .team .team-row .email {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 31rem) {
  .exhibit h1 span {
    display: none;
  }
}
.exhibit .banner .content-container p {
  margin-bottom: 1rem;
}
.exhibit .banner .img-container {
  background-image: url("./../../img/exposer.jpeg");
}
.exhibit .exhibit-intro {
  margin: 3rem auto;
}
@media screen and (max-width: 31rem) {
  .exhibit .exhibit-intro {
    margin: 1.5rem auto 3rem;
  }
}
.exhibit .exhibit-intro p {
  margin-bottom: 1rem;
}
.exhibit .exhibit-process .title {
  color: #005995;
  font-size: 1.4rem;
  font-family: "Lato Bold";
  margin-bottom: 3rem;
}
@media screen and (max-width: 31rem) {
  .exhibit .exhibit-process .title {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.exhibit .exhibit-process .steps, .exhibit .exhibit-process .steps-mb {
  margin-bottom: 4rem;
}
.exhibit .exhibit-process .steps .step:first-child .step-header, .exhibit .exhibit-process .steps-mb .step:first-child .step-header {
  background-image: url("./../../img/step1.jpg");
}
.exhibit .exhibit-process .steps .step:nth-child(2) .step-header, .exhibit .exhibit-process .steps-mb .step:nth-child(2) .step-header {
  background-image: url("./../../img/step2.jpg");
}
.exhibit .exhibit-process .steps .step:nth-child(3) .step-header, .exhibit .exhibit-process .steps-mb .step:nth-child(3) .step-header {
  background-image: url("./../../img/step3.jpg");
  background-position: center;
}
.exhibit .exhibit-process .steps .step .step-header, .exhibit .exhibit-process .steps-mb .step .step-header {
  background-size: cover;
  background-position: center;
  height: 6.25rem;
  position: relative;
  overflow: hidden;
}
.exhibit .exhibit-process .steps .step .step-header .number, .exhibit .exhibit-process .steps-mb .step .step-header .number {
  position: absolute;
  left: 0;
  background-color: #fff;
  border: 3px solid #005995;
  border-radius: 50%;
  height: 6.25rem;
  width: 6.25rem;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: right;
  font-family: "Lato Bold";
}
.exhibit .exhibit-process .steps .step .step-header .number span, .exhibit .exhibit-process .steps-mb .step .step-header .number span {
  font-size: 3.75rem;
  transform: translateX(-1rem);
  color: #005995;
}
.exhibit .exhibit-process .steps .step .step-content, .exhibit .exhibit-process .steps-mb .step .step-content {
  border-left: 4px dashed #005995;
}
.exhibit .exhibit-process .steps .step .step-content .content-container, .exhibit .exhibit-process .steps-mb .step .step-content .content-container {
  width: 90%;
  max-width: 56rem;
  margin: auto;
  padding: 2rem 0 4rem;
}
.exhibit .exhibit-process .steps .step .step-content .content-container p, .exhibit .exhibit-process .steps-mb .step .step-content .content-container p {
  margin-bottom: 1rem;
}
.exhibit .exhibit-process .steps .step .step-content .content-container .btn-container, .exhibit .exhibit-process .steps-mb .step .step-content .content-container .btn-container {
  text-align: center;
}
.exhibit .exhibit-process .steps .step:last-child .content-container, .exhibit .exhibit-process .steps-mb .step:last-child .content-container {
  padding-bottom: 2rem;
}
@media screen and (max-width: 31rem) {
  .exhibit .exhibit-process .steps {
    display: none;
  }
}
.exhibit .exhibit-process .steps-mb {
  margin-bottom: 4rem;
}
@media screen and (min-width: 31rem) {
  .exhibit .exhibit-process .steps-mb {
    display: none;
  }
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit {
  padding: 0.6rem;
  margin-bottom: 1rem;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .step {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 4px rgba(0, 0, 0, 0.25);
  height: auto;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .step .step-content {
  border-left: none;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .step .step-content .btn-container {
  margin-bottom: 4rem;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-next, .exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-prev {
  top: 90%;
  display: flex;
  flex-direction: column;
  width: 100px;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-next::after, .exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-prev::after {
  content: "";
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-next img, .exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-prev img {
  height: 30px;
  width: 30px;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-next p, .exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-prev p {
  font-size: 0.8rem;
  color: #005995;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-next {
  right: 6%;
}
.exhibit .exhibit-process .steps-mb .swiper-exhibit .swiper-button-prev {
  left: 6%;
}

@media screen and (max-width: 31rem) {
  .exhibitors h1 span {
    display: none;
  }
}
.exhibitors .banner .btn-container a + a {
  margin-left: 2rem;
}
@media screen and (max-width: 31rem) {
  .exhibitors .banner .btn-container a + a {
    margin-left: 0;
  }
}
.exhibitors .banner .img-container {
  background-image: url("./../../img/exposants.jpeg");
}
.exhibitors .exhibitors-container {
  display: flex;
  justify-content: center;
  margin: 3rem auto;
}
@media screen and (max-width: 41rem) {
  .exhibitors .exhibitors-container {
    display: block;
  }
}
.exhibitors .exhibitors-container .exhibitors-block {
  margin-bottom: 2rem;
}
.exhibitors .exhibitors-container .exhibitors-block h2 {
  font-size: 1.5rem;
  color: #005995;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 41rem) {
  .exhibitors .exhibitors-container .exhibitors-block h2 {
    text-align: left;
    font-size: 1rem;
  }
}
@media screen and (max-width: 41rem) {
  .exhibitors .exhibitors-container .exhibitors-block .exhibitors-list {
    border-left: 2px solid #005995;
    padding-left: 1rem;
  }
}
.exhibitors .exhibitors-container .exhibitors-block .exhibitors-list p, .exhibitors .exhibitors-container .exhibitors-block .exhibitors-list a {
  font-size: 1rem;
  line-height: 24px;
  color: #424242;
}
.exhibitors .exhibitors-container .exhibitors-block .exhibitors-list a {
  display: block;
}
.exhibitors .exhibitors-container .exhibitors-block .exhibitors-list a:hover {
  color: #E85c5b;
}
.exhibitors .exhibitors-container .exhibitors-left {
  width: 100%;
  text-align: right;
  border-right: 3px solid #E85c5b;
  padding-right: 3rem;
}
@media screen and (max-width: 41rem) {
  .exhibitors .exhibitors-container .exhibitors-left {
    text-align: left;
    border-right: none;
    padding-right: 0;
  }
}
.exhibitors .exhibitors-container .exhibitors-right {
  width: 100%;
  padding-left: 3rem;
  text-align: left;
}
@media screen and (max-width: 41rem) {
  .exhibitors .exhibitors-container .exhibitors-right {
    padding-left: 0;
  }
}

.info .banner .container {
  display: block;
}
.info .banner .container .content-container {
  text-align: center;
  margin-right: 0;
  padding: 1rem 0;
}
@media screen and (max-width: 31rem) {
  .info .banner .container .content-container {
    padding: 1rem 0;
  }
}
.info .banner .container .content-container p {
  font-size: 2.5rem;
  line-height: 1.2;
}
@media screen and (max-width: 41rem) {
  .info .banner .container .content-container p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 31rem) {
  .info .banner .container .content-container p {
    font-size: 1.125rem;
  }
}
.info .info-container {
  display: flex;
  margin: 4rem auto;
}
@media screen and (max-width: 56rem) {
  .info .info-container {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 31rem) {
  .info .info-container {
    margin: 2rem auto;
  }
}
.info .info-container .container-left {
  border-right: 2px solid #E85c5b;
  width: 100%;
}
@media screen and (max-width: 56rem) {
  .info .info-container .container-left {
    border-right: none;
    text-align: center;
    max-width: 40rem;
    margin: 2rem auto 0;
  }
}
.info .info-container .container-left .acess-plan-container {
  width: 90%;
  margin-bottom: 3rem;
}
@media screen and (max-width: 56rem) {
  .info .info-container .container-left .acess-plan-container {
    margin: auto;
    margin-bottom: 1rem;
  }
}
.info .info-container .container-left .acess-plan-container img {
  width: 100%;
  object-fit: contain;
}
.info .info-container .container-left iframe {
  width: 90%;
  height: 50vh;
  max-height: 35rem;
}
.info .info-container .container-right {
  width: 100%;
}
@media screen and (max-width: 56rem) {
  .info .info-container .container-right {
    max-width: 38rem;
    margin: auto;
  }
}
.info .info-container .container-right .info-block {
  display: flex;
  margin-left: 8%;
  margin-bottom: 4rem;
}
@media screen and (max-width: 56rem) {
  .info .info-container .container-right .info-block {
    margin-left: auto;
    margin-bottom: 2rem;
  }
}
.info .info-container .container-right .info-block .info-title {
  color: #005995;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Lato Bold";
}
@media screen and (max-width: 31rem) {
  .info .info-container .container-right .info-block .info-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}
.info .info-container .container-right .info-block .img-container {
  height: 75px;
  margin-right: 10%;
}
@media screen and (max-width: 56rem) {
  .info .info-container .container-right .info-block .img-container {
    margin-right: 5%;
    height: 50px;
  }
}
.info .info-container .container-right .info-block .img-container img {
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 56rem) {
  .info .info-container .container-right .info-block .info-content {
    border-left: 2px solid #005995;
    padding-left: 5%;
  }
  .info .info-container .container-right .info-block .info-content ul {
    padding-left: 1rem;
  }
}

.login form, .reset-password form {
  width: 95%;
  margin: 0 auto 5rem;
  max-width: 25rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 4px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem;
}
.login form .login-error, .reset-password form .login-error {
  color: #E85c5b;
  text-align: center;
  margin-bottom: 1rem;
}
.login form .form-row, .reset-password form .form-row {
  margin-bottom: 1rem;
}
.login form .form-row label, .reset-password form .form-row label {
  display: block;
  margin-bottom: 0.5rem;
}
.login form .form-row input, .reset-password form .form-row input {
  width: 100%;
}
.login form .btn-container, .reset-password form .btn-container {
  margin-top: 1rem;
  text-align: center;
}
.login form a, .reset-password form a {
  text-align: center;
  display: block;
  margin-top: 1rem;
  color: #005995;
}
.login form a:hover, .reset-password form a:hover {
  color: #E85c5b;
}

.reservation .form-container {
  display: flex;
}
@media screen and (max-width: 41rem) {
  .reservation .form-container {
    display: block;
  }
}
.reservation .form-container h2 {
  margin-bottom: 2rem;
}
.reservation .form-container .container-left, .reservation .form-container .container-right {
  width: 100%;
}
.reservation .form-container .container-left {
  border-right: 2px solid #005995;
  padding-right: 3rem;
}
@media screen and (max-width: 41rem) {
  .reservation .form-container .container-left {
    border-right: none;
    padding-right: 0;
  }
}
.reservation .form-container .container-right {
  margin-left: 3rem;
}
@media screen and (max-width: 41rem) {
  .reservation .form-container .container-right {
    margin-left: 0;
  }
}
.reservation .form-container .container-right .plan-link {
  font-family: "Lato Bold";
  color: #005995;
  margin-bottom: 1rem;
  display: inline-block;
}
.reservation .form-container .container-right .plan-link i {
  margin-right: 5px;
}
.reservation .form-container .container-right .pack-location--details {
  padding: 0.5rem;
  border-radius: 5px;
  background-color: rgb(241, 241, 241);
  margin-bottom: 1rem;
}
.reservation .form-container .container-right .pack-location--details p {
  font-size: 0.9rem;
}
.reservation .form-container .container-right .pack-location--details p:first-child {
  font-family: "Lato Bold";
}
.reservation .form-container .form-row {
  margin-bottom: 1rem;
}
.reservation .form-container .form-row label {
  display: block;
  margin-bottom: 0.4rem;
  line-height: 1.3;
}
@media screen and (max-width: 31rem) {
  .reservation .form-container .form-row label {
    font-size: 0.75rem;
  }
}
.reservation .form-container .form-row input {
  width: 100%;
  max-width: 25rem;
}
@media screen and (max-width: 41rem) {
  .reservation .form-container .form-row input {
    max-width: none;
  }
}
.reservation .form-container .form-row ul {
  padding-left: 0;
  list-style: none;
  margin: 0.2rem 0;
}
.reservation .form-container .form-row ul li {
  color: #E85c5b;
  font-family: "Lato Bold";
}
.reservation .form-container .row-2-col {
  display: flex;
  justify-content: space-between;
  max-width: 25rem;
}
.reservation .form-container .row-2-col div + div {
  margin-left: 1rem;
}
.reservation .form-container .row-location {
  display: flex;
}
.reservation .form-container .row-location .form-row + .form-row {
  margin-left: 1rem;
}
.reservation .form-container .row-pack-img {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.reservation .form-container .row-pack-img label {
  display: inline;
  margin-bottom: 0;
}
.reservation .form-container .row-pack-img input {
  width: 2rem;
  outline: none;
}
.reservation .recap {
  background-color: #005995;
  color: #fff;
  padding: 1rem;
  margin: 2rem auto;
}
.reservation .recap .recap--inner {
  display: flex;
  margin: auto;
}
@media screen and (max-width: 56rem) {
  .reservation .recap .recap--inner {
    display: block;
  }
}
.reservation .recap .recap--inner h2 {
  margin-bottom: 1rem;
}
.reservation .recap .recap--inner > div {
  width: 100%;
}
.reservation .recap .recap--inner .price-details {
  margin-left: 10%;
}
@media screen and (max-width: 31rem) {
  .reservation .recap .recap--inner .price-details {
    margin-left: 0;
  }
}
@media screen and (max-width: 31rem) {
  .reservation .recap .recap--inner .price-details .price-details--inner {
    width: fit-content;
    margin: auto;
  }
}
.reservation .recap .recap--inner .price-details .label {
  display: inline-block;
  width: 8rem;
  text-align: right;
}
@media screen and (max-width: 31rem) {
  .reservation .recap .recap--inner .price-details .label {
    font-size: 1rem;
    width: 6rem;
  }
}
.reservation .recap .recap--inner .price-details p {
  color: #fff;
  font-size: 1.1rem;
}
.reservation .recap .recap--inner .price-details p:not(:last-child) {
  margin-top: 1rem;
}
.reservation .recap .recap--inner .price-details p span[class*=price] {
  margin-left: 2rem;
  letter-spacing: 0.6px;
  border-bottom: 1px dashed #fff;
  display: inline-block;
  width: 6rem;
}
.reservation .recap .recap--inner .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservation-summary .summary-header {
  text-align: center;
}
.reservation-summary .summary-header h1 {
  margin-bottom: 1rem;
}
.reservation-summary .summary-content {
  width: fit-content;
  margin: 2rem auto 0;
}
.reservation-summary .summary-content h2 {
  margin-bottom: 1rem;
}
.reservation-summary .summary-content .row {
  display: flex;
  margin-bottom: 1rem;
}
.reservation-summary .summary-content .row .label {
  font-family: "Lato Bold";
  margin-right: 1rem;
}
.reservation-summary .summary-content .row p:nth-child(2) {
  border-bottom: 1px dashed rgb(179, 179, 179);
}
.reservation-summary .summary-content .row-2-col div {
  width: 100%;
}
.reservation-summary .summary-content .row-2-col div + div {
  margin-left: 1rem;
}
.reservation-summary .recap {
  background-color: #005995;
  color: #fff;
  padding: 1rem;
  margin: 2rem auto;
}
.reservation-summary .recap .recap--inner {
  display: flex;
  margin: auto;
}
.reservation-summary .recap .recap--inner h2 {
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: center;
}
.reservation-summary .recap .recap--inner > div {
  width: 100%;
}
.reservation-summary .recap .recap--inner .price-details {
  width: max-content;
  margin: 2rem auto;
}
.reservation-summary .recap .recap--inner .price-details .label {
  display: inline-block;
  width: 7rem;
  text-align: right;
}
.reservation-summary .recap .recap--inner .price-details p {
  color: #fff;
  font-size: 1.1rem;
}
.reservation-summary .recap .recap--inner .price-details p:not(:last-child) {
  margin-top: 1rem;
}
.reservation-summary .recap .recap--inner .price-details p span[class*=price] {
  margin-left: 2rem;
  letter-spacing: 0.6px;
  border-bottom: 1px dashed #fff;
  width: 7rem;
  display: inline-block;
}

.reservation-pdf h2 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.reservation-pdf label {
  margin-right: 1rem;
  font-family: "Lato Bold";
}
.reservation-pdf input {
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed #adadad;
  font-size: 1rem;
  padding: 0;
}
.reservation-pdf .form-row {
  margin-bottom: 1rem;
}
.reservation-pdf .btn-container {
  text-align: center;
  margin-top: 2rem;
}
.reservation-pdf .btn-container .btn {
  padding: 0.8rem 1rem;
  margin: 0 1rem;
}
.reservation-pdf select {
  background-color: #fff;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.reservation-pdf .pack-location {
  margin: 1rem 0;
}
.reservation-pdf .pack-location .location {
  font-family: "Lato Bold";
}
.reservation-pdf .row-pack {
  display: flex;
  align-items: center;
}
.reservation-pdf .recap {
  padding: 1rem;
  margin: 2rem auto;
  border: 2px solid #005995;
}
.reservation-pdf .recap .recap--inner {
  display: flex;
  margin: auto;
}
.reservation-pdf .recap .recap--inner h2 {
  margin-bottom: 1rem;
  margin-top: 0;
}
.reservation-pdf .recap .recap--inner > div {
  width: 100%;
}
.reservation-pdf .recap .recap--inner .price-details {
  margin-left: 10%;
}
.reservation-pdf .recap .recap--inner .price-details .label {
  display: inline-block;
  width: 8rem;
  text-align: right;
}
.reservation-pdf .recap .recap--inner .price-details p {
  color: #005995;
  font-size: 1.1rem;
  font-family: "Lato Bold";
}
.reservation-pdf .recap .recap--inner .price-details p:not(:last-child) {
  margin-top: 1rem;
}
.reservation-pdf .recap .recap--inner .price-details p span[class*=price] {
  border-bottom: 1px dashed #005995;
  margin-left: 2rem;
  letter-spacing: 0.6px;
  display: inline-block;
  width: 5rem;
}
.reservation-pdf .recap ~ .form-row {
  display: none;
}

.error {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error .header, .error .menu-mb, .error .partners, .error .footer {
  display: none;
}
.error .error-content {
  text-align: center;
}
.error .error-content h1 {
  font-size: 5rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
}
.error .error-content p {
  font-size: 1.5rem;
  line-height: 1.3;
}
.error .error-content a {
  font-family: "Lato Bold";
  color: #005995;
  display: inline-block;
  margin-top: 1rem;
}

.terms section {
  margin-bottom: 2rem;
}
.terms section a {
  color: #005995;
  display: block;
  margin: 0.5rem 0;
}
.terms section h2 {
  margin-bottom: 0.5rem;
  text-align: left;
}