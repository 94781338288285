.info{
    .banner{
       .container{
            display: block;
            .content-container{
                text-align: center;
                margin-right: 0;
                padding: 1rem 0;
                @media screen and (max-width : $md) {
                   padding: 1rem 0;
                }
                p{
                    font-size: 2.5rem;
                    line-height: 1.2;
                    @media screen and (max-width : $lg) {
                        font-size: 1.8rem;
                     }
                    @media screen and (max-width : $md) {
                       font-size: 1.125rem;
                    }
                }
            }
       }
    }

    .info-container{
        display: flex;
        margin: 4rem auto;

        @media screen and (max-width : $xl) {
            flex-direction: column-reverse;
        }

        @media screen and (max-width : $md) {
            margin: 2rem auto;
        }
        .container-left{
            border-right: 2px solid $secondary;
            width: 100%;
            @media screen and (max-width : $xl) {
                border-right: none;
                text-align: center;
                max-width: 40rem;
                margin: 2rem auto 0;
            }
            .acess-plan-container{
                width: 90%;
                margin-bottom: 3rem;
                @media screen and (max-width : $xl) {
                    margin: auto;
                    margin-bottom: 1rem;
                }

                img{
                    width: 100%;
                    object-fit: contain;
                }
            }

            iframe{
                width: 90%;
                height: 50vh;
                max-height: 35rem;
            }
        }

        .container-right{
            width: 100%;
            @media screen and (max-width : $xl) {
                max-width: 38rem;
                margin: auto;
            }
        
            .info-block{
                display: flex;
                margin-left: 8%;
                margin-bottom: 4rem;
                @media screen and (max-width : $xl) {
                    margin-left: auto;
                    margin-bottom: 2rem;
                }

                .info-title{
                    color: $primary;
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                    font-family: "Lato Bold";
                    @media screen and (max-width : $md) {
                       font-size: 1rem;
                       margin-bottom: 0.5rem;
                    }
                }
                .img-container{
                    height: 75px;
                    margin-right: 10%;
                    @media screen and (max-width : $xl) {
                       margin-right: 5%;
                       height: 50px;
                    }
                    img{
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .info-content{
                    @media screen and (max-width : $xl) {
                        border-left: 2px solid $primary;
                        padding-left: 5%;

                        ul{
                            padding-left: 1rem;
                        }
                    }
                }
            }
        }

    }
}