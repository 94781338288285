.header{
    background-color: $white;
    padding: 1rem 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 100;

    @media screen and (max-width: $md) {
        padding: 0.5rem 0;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

       .logo-container{
            display: flex;
            width: 250px;
            align-items: center;
            .logo{
                width: 85px;
                @media screen and (max-width: $md) {
                    width: 60px;
                }
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }

            .logo-partner{
                text-align: center;
                width: 165px;
                margin-left: 1rem;
                @media screen and (max-width: $md) {
                    width: 130px;
                }
               p{
                font-family: "Lato Bold";
                color: $primary;
                margin-bottom: 0.2rem;
                font-size: 1rem;
                @media screen and (max-width: $md) {
                    font-size: 0.8rem;
                }
               }
                img{
                    width: 93px;
                }
            }
       }

        nav{
            ul{
                display: flex;
                list-style: none ;
                li + li {
                    margin-left: 2rem;
                }
                a{
                    color: $primary;
                    font-family: "Lato Bold";
                    font-size: 1.25rem;
                }
            }
        }

        .burger-icon{
            display: none;
            cursor: pointer;
            transform: scale(1.2);
        }
    }

    @media screen and (max-width: 66rem){
        .container {
            nav{
                display: none;
            }

            .burger-icon{
                display: inline-block;
            }
        }
    }
}

.menu-mb{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 50;

    @media screen and (max-width: 66rem){
        display: block;

        nav {
            width: 90%;
            margin: 2rem auto 0;
            height: calc(100% - 350px);
            max-height: 27rem;

            ul{
                list-style: none;
                text-align: right;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                a{
                    color: $primary;
                    font-family: "Lato Bold";
                    font-size: 1.25rem;
                }
            }
        }

        .socials{
            margin: auto;
            width: fit-content;
            height: 200px;
            display: flex;
            align-items: center;

            a {
                background-color: $primary;
                border-radius: 50%;
                display: inline-block;
                padding: 0.5rem;

                img{
                    height: 100%;
                    object-fit: contain;
                }

                &.fb{
                    height: 60px;
                    width: 60px;
                    margin-right: 3rem;
                }
                &.linkedin{
                    height: 65px;
                    width: 65px;
                }
            }
        }
    }

}