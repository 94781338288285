body {
    font-family: "Lato";
}

.container {
    max-width: 71.25rem; //1140px
    margin: auto;
    width: 90%;
}

h1 {
    font-family: "Lato";
    font-size: 1.875rem;
    line-height: 1.2;
    color: $secondary;
    text-transform: uppercase;
    text-align: center;
    margin: 3rem auto;

    @media screen and (max-width: $xl) {
        margin: 2rem auto;
        font-size: 1.25rem;
    }
}

h2 {
    font-family: "Lato Bold";
    font-size: 1.5rem;
    line-height: 1.2;

    @media screen and (max-width: $md) {
        font-size: 1.25rem;
        text-align: center;
    }
}

p,
li {
    line-height: 24px;
    font-family: "Lato";
    font-size: 1rem;
    color: $text;

    @media screen and (max-width: $md) {
        font-size: 0.75rem;
        line-height: 20px;
    }
}

.p-bold {
    font-family: "Lato Bold";
}

.p-italic {
    font-family: "Lato Italic";
}


ul {
    list-style: disc;
    padding-left: 2rem;

    li {
        margin-bottom: 0.5rem;
    }
}

input,
select {
    border-radius: 4px;
    padding: 0.5rem;
    background-color: rgb(241 241 241);
    border: 1px solid rgb(218, 218, 218);

    &:focus {
        outline: 2px solid $primary;
    }
}

.alert {
    text-align: center;
    display: block;
    width: 90%;
    margin: 0 auto 2rem;
}

.alert-success {
    color: $green;
}

.alert-error {
    color: $secondary;
}

.btn {
    font-family: "Lato Bold";
    font-size: 0.875rem;
    text-transform: uppercase;
    border: 1px solid $secondary;
    width: fit-content;
    background-color: $secondary;
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    color: $white;
    display: inline-block;
    transition: 0.4s;
    cursor: pointer;

    &.disabled {
        background-color: $primary;
        border: none;

        &:hover {
            background-color: $primary;
            cursor: default;
            color: $white;
        }
    }

    &:hover {
        color: $secondary;
        background-color: $white;
    }
}

.display-none {
    display: none;
}

.overflow-hidden {
    overflow: hidden;
}

.btn-container {
    @media screen and (max-width: $md) {
        text-align: center;
    }
}

.banner {
    background-color: $primary;
    color: $white;
    padding: 6px 0;

    @media screen and (max-width: $xl) {
        padding: 1rem 0;
    }

    p {
        color: $white
    }

    .container {
        display: flex;
        justify-content: space-between;

        .img-container {
            width: 15.6rem;
            min-height: 100%;
            min-width: 250px;
            background-image: url("./../../img/img-site-02.jpg");
            background-size: cover;
            background-position: center;

            @media screen and (max-width: $xl) {
                display: none;
            }
        }

        .content-container {
            margin: 1rem 0;
            margin-right: 2rem;

            @media screen and (max-width: $xl) {
                margin-right: 0;
            }

            p {
                font-size: 1.125rem;

                @media screen and (max-width: $xl) {
                    font-size: 0.875rem;
                }
            }
        }
    }
}

.banner-img-left {
    .container {
        flex-direction: row-reverse;

        .img-container {
            margin-right: 10%;
        }
    }
}

.banner-img-right {
    .content-container {
        max-width: 46rem;
    }
}


.swiper {
    .swiper-pagination-bullet {
        background-color: #D9D9D9;
        opacity: 1;

        &.swiper-pagination-bullet-active {
            background-color: #808080;
        }
    }
}