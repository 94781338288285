@font-face {
    font-family: 'Lato';
    src:  url('../../fonts/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Italic';
    src:  url('../../fonts/Lato-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Bold';
    src:  url('../../fonts/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Light';
    src:  url('../../fonts/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'Fahkwang SemiBold';
    src:  url('../../fonts/Fahkwang-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
