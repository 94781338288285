.error{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .header, .menu-mb, .partners, .footer{
        display: none;
    }
    .error-content{
    text-align: center;
        h1{
            font-size: 5rem;
            margin-bottom: 1.5rem;
            margin-top: 0;
        }

        p{
            font-size: 1.5rem;    
            line-height: 1.3;       
        }

        a{
            font-family: "Lato Bold";
            color: $primary;
            display: inline-block;
            margin-top: 1rem;
        }
   }
}