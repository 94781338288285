.terms{
    section{
        margin-bottom: 2rem;
        a{
            color: $primary;
            display: block;
            margin: 0.5rem 0;
        }
        h2{
            margin-bottom: 0.5rem;
            text-align: left;
        }
    }
}