.login, .reset-password{
    form{
        width: 95%;
        margin: 0 auto 5rem;
        max-width: 25rem;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25), -4px -4px 4px rgba(0, 0, 0, 0.25);
        padding: 2rem 1rem;

        .login-error{
            color:$secondary;
            text-align: center;
            margin-bottom: 1rem;
        }

        .form-row{
            margin-bottom: 1rem;
           label{
                display: block;
                margin-bottom: 0.5rem;  
           }

           input{
            width: 100%;
           }
        }

        .btn-container{
            margin-top: 1rem;
            text-align: center;
        }

        a{
            text-align: center;
            display: block;
            margin-top: 1rem;
            color: $primary;

            &:hover{
                color: $secondary;
            }
        }
    }
}