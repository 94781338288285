.footer {
    margin-top: 3rem;
    background-color: $primary;
    @media screen and (max-width: $xl){
        display: none;
    }

    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            display: flex;
            list-style: none;
            padding-left: 0;
            
            li{
                color: $white; 
                margin-bottom: 0;
                font-size: 0.75rem;
                &:first-child{

                    padding-right: 1rem;
                }

                &:not(:first-child){
                    border-left: 1px solid $white;
                    padding: 0 1rem;
                }
                a{
                    color: $white; 
                    font-size: 0.75rem;
                }
            }
        }

        .socials {
            display: flex;
            align-items: center;

            a {
                img{
                    height: 100%;
                    object-fit: contain;
                }
                &.fb{
                    height: 30px;
                    margin-right: 3rem;
                }
                &.linkedin{
                    height: 37px;
                }
            }
        }
    }
}

.partners{
    h2{
        font-family: "Lato";
        font-size: 1.875rem;
        color: $secondary;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        @media screen and (max-width: $md) {
            font-size: 1.25rem;
        }
    }
}

.swiper-partners{
    margin-top: 2rem;
    width: 100%;
    height: 100px;
    text-align: center;
    @media screen and (max-width: $xl){
        margin-bottom: 3rem;
        margin-top: 0;
    }

    a{
        display: inline-block;
        height: 100px;
        max-width: 160px;
        @media screen and (max-width: $md){
            height: 80px;;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }


    .swiper-button-next, .swiper-button-prev{
        color: $primary;

        &::after{
            font-size: 2rem;
        }
    }
}