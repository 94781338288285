.home {
    h1 {
        font-family: "Lato Bold";
        color: $white;
        font-size: 2.25rem;
        text-transform: capitalize;
        text-align: left;
        margin: 0;

        @media screen and (max-width: $md) {
            font-size: 1.25rem;
        }
    }

    .banner {
        .content-container {
            margin: 1.5rem 0;

            @media screen and (max-width: $md) {
                margin: 1rem 0;
            }

            .sup-title {
                color: $white;
                font-size: 1.25rem;
                margin-bottom: 1.5rem;

                @media screen and (max-width: $md) {
                    font-size: 0.75rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    .hero-video {
        margin-top: 2rem;

        iframe {
            width: 100%;
            height: 50vh;
        }
    }

    .introduction {
        margin: 3rem auto;

        .data-circle-container {
            margin: 3rem auto;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            max-width: 65rem;
            row-gap: 1rem;

            @media screen and (max-width: $lg) {
                grid-template-columns: repeat(2, 1fr);
            }

            .data-circle {
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .circle {
                    width: 120px;
                    height: 120px;
                    border: 7px solid $primary;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $secondary;
                    font-family: "Lato Light";
                    font-size: 3.125rem;
                }

                .description {
                    color: $primary;
                    max-width: 120px;
                    text-align: center;
                    margin-top: 0.5rem;
                    font-size: 0.875rem;
                    line-height: 1.2;
                }
            }
        }
    }

    .cta-2-col {
        display: flex;
        text-align: center;

        @media screen and (max-width: $lg) {
            flex-direction: column;
        }

        .p-bold {
            margin-bottom: 1rem;
        }

        div:first-child {
            border-right: 2px solid $primary;
            padding-right: 2rem;

            @media screen and (max-width: $lg) {
                border-right: none;
                padding-right: 0;
                margin: 1rem auto 3rem;
            }
        }

        div:nth-child(2) {
            padding-left: 2rem;

            @media screen and (max-width: $lg) {
                padding-left: 0;
            }
        }

        @media screen and (max-width: $lg) {
            div {
                max-width: 28rem;
                margin: auto;
            }
        }
    }

    section.news {
        margin: 5rem auto;
        padding: 2rem 0;

        @media screen and (max-width: $lg) {
            background-color: $primary;
            width: 100%;
        }

        h2 {
            margin-bottom: 1rem;

            @media screen and (max-width: $lg) {
                color: $white;
                text-align: center;
            }
        }

        .news-container {
            @media screen and (max-width: $lg) {
                display: none;
            }
        }

        .news-image {
            min-width: 30%;
            background-size: cover;
            background-position: center;
        }

        .news-single {
            display: flex;
            margin-bottom: 3px;
            background-color: $primary;

            .news-content {
                padding: 1rem;

                p {
                    color: $white;
                }

                a {
                    color: #db8989;
                }
            }

            &.bg-white {
                background-color: $white;

                p {
                    color: $text;
                }

                a {
                    color: $secondary;
                }

            }

            strong {
                font-family: "Lato Bold";
            }
        }

        .swiper-news {
            display: none;

            @media screen and (max-width: $lg) {
                display: block;
            }

            .swiper-wrapper {
                margin-top: 1.5rem;

                .swiper-slide {
                    background-color: $white;
                    padding: 3px;

                    .news-image {
                        height: 150px;
                        background-size: cover;
                        background-position: center;
                    }

                    .news-content {
                        .news-title {
                            background-color: $secondary;
                            color: $white;
                            padding: 0.8rem;
                            font-family: "Lato";
                        }

                        .content-bottom {
                            padding: 1rem 0.8rem;

                            .news-date {
                                margin-top: 2rem;
                                text-align: right;
                            }
                        }

                        a {
                            color: $secondary
                        }
                    }
                }
            }
        }
    }
}