.reservation{
    .form-container{
        display: flex;

        @media screen and (max-width: $lg){
            display: block;
        }

        h2{
            margin-bottom: 2rem;
        }

        .container-left, .container-right{
            width: 100%;
        }
        .container-left{
            border-right: 2px solid $primary;
            padding-right: 3rem;

            @media screen and (max-width: $lg){
                border-right: none;
                padding-right: 0;    
            }
        }    
        .container-right{
            margin-left: 3rem;
    
            @media screen and (max-width: $lg){
                margin-left: 0;
            }

            .plan-link{
                font-family: "Lato Bold";
                color: $primary;
                margin-bottom: 1rem;
                display: inline-block;
                i{
                    margin-right: 5px;
                }
            }

            .pack-location--details{
                padding: 0.5rem;
                border-radius: 5px;
                background-color: rgb(241, 241, 241);
                margin-bottom: 1rem;
                p{
                    font-size: 0.9rem;
                }
                p:first-child{
                    font-family: "Lato Bold";
                }
            }
        }

        .form-row{
            margin-bottom: 1rem;
            label{
                display: block;
                margin-bottom: 0.4rem;
                line-height: 1.3;
                @media screen and (max-width: $md){
                    font-size: 0.75rem;
                }
            }
            input{
                width: 100%;
                max-width: 25rem;
                @media screen and (max-width: $lg){
                    max-width: none  
                }
            }


            ul {
                padding-left: 0;
                list-style: none;
                margin: 0.2rem 0;
                li{
                    color: $secondary;
                    font-family: "Lato Bold";
                }
            }
        }

        .row-2-col{
            display: flex;
            justify-content: space-between;
            max-width: 25rem;
            div + div{
                margin-left: 1rem;
            }   
        }

        .row-location{
            display: flex;
           .form-row + .form-row{
            margin-left: 1rem;
           }
        }

        .row-pack-img{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;

            label{
                display: inline;
                margin-bottom: 0;
            }
            input{
                width: 2rem;
                outline: none;
            }
        }
    }

    .recap{
        background-color: $primary;
        color: $white;
        padding: 1rem;
        margin: 2rem auto;
        .recap--inner{
            display: flex;
            margin: auto;
            @media screen and (max-width: $xl){
                display: block 
            }
            h2{
                margin-bottom: 1rem;
            }

            >div{
                width: 100%;
            }

            .price-details{
                margin-left: 10%;
                @media screen and (max-width:$md) {
                    margin-left: 0;
                }
                .price-details--inner{
                    @media screen and (max-width:$md) {
                        width: fit-content;
                        margin: auto;
                    }
                }
                .label{
                    display: inline-block;
                    width: 8rem;
                    text-align: right;
                    @media screen and (max-width:$md) {
                        font-size: 1rem;
                        width: 6rem;
                    }
                }
                p{
                    color: $white;
                    font-size: 1.1rem;
    
                    &:not(:last-child){
                        margin-top: 1rem;
                    }

                    span[class*="price"]{
                        margin-left: 2rem;
                        letter-spacing: 0.6px;
                        border-bottom: 1px dashed $white;
                        display: inline-block;
                        width: 6rem;
                    }

                }
            }
    
            .btn-container{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}