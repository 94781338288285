.about{
    .about-content{
        margin: 3rem auto;

        .img-container{
            display: flex;
            justify-content: space-around;
            margin: 3rem auto;

            @media screen and (max-width:$lg){
                display: none;
            }

            div{
                height: 150px;
                img{
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .swiper-about{
            display: none;
            margin: 2rem auto;
            max-width: 20rem;
            padding-bottom: 2rem;
            @media screen and (max-width:$lg){
                display: block;
            }
            .swiper-slide{
                div{
                    height: 150px;
                    text-align: center;
                    @media screen and (max-width:$md){
                        height: 120px;
                    }
                    img{
                        height: 100%;
                        object-fit: contain;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}