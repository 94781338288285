.reservation-pdf{
    h2 {
        margin-bottom: 1rem; 
        margin-top: 2rem;
    }
    label{
       margin-right: 1rem;
       font-family: "Lato Bold";
    }

    input{
        background-color: transparent;
        border: none;
        border-bottom: 1px dashed #adadad;
        font-size: 1rem;
        padding: 0;
    }

    .form-row{
        margin-bottom: 1rem;
    }

    .btn-container{
        text-align: center;
        margin-top: 2rem;
        .btn{
            padding: 0.8rem 1rem;
            margin: 0 1rem;
        }
    }

    select{
        background-color: $white;
        font-size: 1rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        
    }

    .pack-location{
        margin: 1rem 0;

        .location{
            font-family: "Lato Bold";
        }
    }

    .row-pack{
        display: flex;
        align-items: center;
    }

    .recap{
        // background-color: $primary;
        // color: $white;
        padding: 1rem;
        margin: 2rem auto;
        border: 2px solid $primary;
        .recap--inner{
            display: flex;
            margin: auto;
            h2{
                margin-bottom: 1rem;
                margin-top: 0;
            }

            >div{
                width: 100%;
            }

            .price-details{
                margin-left: 10%;
                .label{
                    display: inline-block;
                    width: 8rem;
                    text-align: right;
                }
                p{
                    color: $primary;
                    font-size: 1.1rem;
                    font-family: "Lato Bold";
                    &:not(:last-child){
                        margin-top: 1rem;
                    }
                    
                    span[class*="price"]{
                        border-bottom: 1px dashed $primary;
                        margin-left: 2rem;
                        letter-spacing: 0.6px;
                        display: inline-block;
                        width: 5rem;
                    }

                }
            }
        }

        ~.form-row{
            display: none;
        }
    }
}